/* You can add global styles to this file, and also import other style files */
/* Default */
/*@import "~bootstrap/dist/css/bootstrap.css";*/
$enable-gradients: true;
$enable-shadows: true;
$enable-rounded: true;
$enable-transitions: true;

@import url('//fonts.googleapis.com/css?family=Source+Sans+Pro');
@import url('//fonts.googleapis.com/css?family=Source+Code+Pro');

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #373a3c;
$gray-900: #212529;
$black: #000;
$blue: #2780E3;
$indigo: #6610f2;
$purple: #613d7c;
$pink: #e83e8c;
$red: #FF0039;
$orange: #f0ad4e;
$yellow: #FF7518;
$green: #3FB618;
$teal: #20c997;
$cyan: #9954BB;
$primary: $blue;
$secondary: $gray-800;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;
$body-color: $gray-800;
$navbar-dark-hover-color: rgba($white,1);




$white: #ffffff;


$theme-colors: (
	"light":      #efefef,
	"dark":       #040300,
	"primary":    #9f543c,
	"secondary":  #5a4631,
	"info":       #06994d,
	"success":    #539a02,
	"warning":    #ffbc51,
	"danger":     #ed0161,
);

$font-family-sans-serif: "Source Sans Pro", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-serif:      Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace:  "Source Code Pro", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:       $font-family-sans-serif !default;
$font-size-base: 		.8rem;

@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "node_modules/bootstrap/scss/mixins/gradients.scss";
